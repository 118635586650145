import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import ProgrammesCompo from '../../components/actions/ProgrammesCompo';
import EventCompo from '../../components/actions/EventCompo';
import ConseilsCompo from '../../components/actions/ConseilsCompo';

// IMPORT COMPONENTS
import './Accueil.css';
import MontageImage from './../../assets/MontagePhotosFlexifit.png';
import DefaultImage from './../../assets/default-empty-image.svg';

// Imports FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faChevronLeft, faUser} from '@fortawesome/pro-solid-svg-icons';
import { faMagnifyingGlass, faBarsFilter, faChartSimple } from '@fortawesome/pro-regular-svg-icons';

import { useQuery } from '../../hooks/useQuery';
import { GET_DASHBORAD_QUERY } from '../../api';

library.add(faChevronLeft, faMagnifyingGlass, faBarsFilter, faChartSimple, faUser);

export default function Accueil() {

  useEffect(() => {
    sessionStorage.removeItem('searchValue');
    sessionStorage.removeItem('filters');
    sessionStorage.removeItem('filterByType');
  }, []);


  const { data: dashboardData, loading, error: errorToken } = useQuery(GET_DASHBORAD_QUERY);

  // Programme a la une
  const nameUser = dashboardData?.viewer?.firstName;
  const avancementUser = dashboardData?.viewer?.userDatas?.avancement;
  const viewerIsFreemium = dashboardData?.viewer?.userDatas?.freemiumSubscription;
  const viewerIsAdmin = dashboardData?.viewer?.roles?.nodes?.some((role) => role.name === "administrator");


  const pinnedProgrammes = dashboardData?.viewer?.userDatas?.programmesPin?.nodes || [];
  const hasPinnedProgrammes = pinnedProgrammes.length > 0;


  const eventUneApp = dashboardData?.dashboard?.appDashboard?.evenement;
  //const eventUneAppEnable = eventUneApp?.activer && viewerIsAdmin;
  const eventUneAppEnable = eventUneApp?.activer;

  const isLocalhost = window.location.hostname === 'localhost';
  const isPreview = process.env.REACT_APP_VERCEL_BRANCH_URL === 'flexifit-git-staging-studioevoldevs-projects.vercel.app';
  const isDevelopment = process.env.REACT_APP_VERCEL_BRANCH_URL === 'flexifit-git-develop-studioevoldevs-projects.vercel.app';
  const isProd = process.env.REACT_APP_VERCEL_BRANCH_URL === 'flexifit-git-main-studioevoldevs-projects.vercel.app';

  const shouldDisplayBlock = () => {
    if (!Array.isArray(eventUneAppEnable) || eventUneAppEnable.length === 0) {
      return false; // Retourne false si le tableau est invalide ou vide
    }
  
    switch (eventUneAppEnable[0]) {
      case 'disable':
        return false; // Ne jamais afficher
      case 'stagging':
        const isStagingEnvironment = isLocalhost || isPreview || isDevelopment;
        return isStagingEnvironment; // Vrai si l'un des trois est vrai
      case 'prod':
        return isProd; // Afficher uniquement en prod
      case 'both':
        return true; // Afficher partout
      default:
        return false; // Par défaut, ne pas afficher
    }
  };
  

  const titleProgrammeUneApp = dashboardData?.dashboard?.appDashboard?.programmeAUneApp?.label;
  const programmeUneApp = dashboardData?.dashboard?.appDashboard?.programmeAUneApp?.programme?.nodes?.[0];
  const nbSeancesProgrammeUne = programmeUneApp?.seances?.planificationProgramme?.reduce(
    (acc, planification) =>
      acc +
      (planification?.synchProgrammesSeances
        ? planification?.synchProgrammesSeances?.nodes?.filter(
            (seance) => viewerIsAdmin || seance.status === "publish"
          ).length
        : 0),
    0
  );
  
   

  // Fonction pour calculer le pourcentage de progression
  const calculateProgression = (totalSeances, completedSeances) => {
    if (totalSeances === 0) return 0;
    return (completedSeances / totalSeances) * 100;
  };

  
  const filteredData = avancementUser?.filter(item => item.programmeId === programmeUneApp?.databaseId.toString());
  const completedSeances = filteredData?.reduce((total, item) => total + item.seances?.length, 0);
  const avancementPerCent = Math.round(calculateProgression(nbSeancesProgrammeUne, completedSeances));

  // Slides a la une
  const slidesApp = dashboardData?.dashboard?.appDashboard?.slidesApp;

  // Conseils a la une
  const conseilsUneApp = dashboardData?.dashboard?.appDashboard?.conseilsUneApp?.nodes;

  const navigate = useNavigate();

  const handleProgrammeClick = (id) => navigate(`/entrainements/programme/${id}`);
  const handleSeanceClick = (id) => navigate(`/entrainements/seance/${id}`);
  const handleConseilClick = (id) => { navigate(`/conseils/${id}`); };

  
  const handleEventClick = (type, id) => {
    switch (type) {
        case 'programmes':
            handleProgrammeClick(id);
            break;
        case 'seances':
            handleSeanceClick(id);
            break;
        case 'conseils':
            handleConseilClick(id);
            break;
        default:
            console.warn('Type inconnu:', type);
    }
  };




  const freemiumAccess = (() => {
    if (viewerIsFreemium) {
        switch (eventUneApp?.lien?.nodes[0]?.contentTypeName) {
            case 'programmes':
                return eventUneApp?.lien?.nodes[0]?.datasProgrammes?.enableFreemiumAccount;
            case 'seances':
                return eventUneApp?.lien?.nodes[0]?.datasSeances?.enableFreemiumAccount;
            case 'conseils':
                return eventUneApp?.lien?.nodes[0]?.configConseils?.enableFreemiumAccount;
            default:
                return true; // Valeur par défaut si contentTypeName est inexistant ou non reconnu
        }
    }
    return true; // Si viewerIsFreemium est false
  })();

 
  return (
    <div className='w-full'>

      <Helmet>
        <title>Flexifit - Plateforme de cours en ligne pour atteindre ses objectifs souplesse</title>
      </Helmet>

      <div className='topBloc background sticked content'>
        <div className='leftSide'>
          <h1>Hello {nameUser}</h1>
        </div>

        <div className='rightSide'>
          <div
            className='IconFavoris userFavoris lg:hidden'
            onClick={() => {
              navigate('/favoris');
            }}
          ><FontAwesomeIcon icon='fa-solid fa-heart' />Mes favoris</div>
          <div
            className='IconProfil lg:hidden'
            onClick={() => {
              navigate('/profil');
            }}
          ><FontAwesomeIcon icon='fa-solid fa-user' /></div>
        </div>

        <div
          onClick={() => navigate('/entrainements', { state: { isModalOpen: true } })}
          className='flex-full mt-4 md:mt-5 searchForm'
        >
          <FontAwesomeIcon className='searchIcon' icon='fa-regular fa-magnifying-glass' />
          <p className='label'>Rechercher un programme ou une séance</p>
          <div className='searchInput' />
          <FontAwesomeIcon className='filterIcon' icon='fa-regular fa-bars-filter' />
        </div>
      </div>

      <div className="blurredPattern peach"></div>

      {loading ? (

        <div className='contentAccueil content'>
          <div className='loading'>
            <FontAwesomeIcon className='animate-spin' icon='fa-duotone fa-spinner-third' />
          </div>
        </div>

      ) : (

        <>

          {shouldDisplayBlock() && (
            <div className='contentAccueil event content'>
              <div className='eventUne'>
                <h2 className='titleUne'>{eventUneApp?.label}</h2>
                  <EventCompo
                    onClick={() => handleEventClick(eventUneApp?.lien?.nodes[0]?.contentTypeName, eventUneApp?.lien?.nodes[0]?.id)}
                    titre={eventUneApp?.titre}
                    alt={eventUneApp?.image?.node?.altText}
                    src={eventUneApp?.image?.node?.sourceUrl}
                    srcSet={eventUneApp?.image?.node?.srcSet}
                    freemiumAccess={freemiumAccess}
                  />
              </div>
            </div>
          )}

          <div className='contentAccueil content'>


            {hasPinnedProgrammes && (
              <div className="slidesBlock pinnedProgrammes">
                <div className="rowSlide">
                  <h2 className="titleUne">
                    {pinnedProgrammes.length === 1
                      ? "Programme épinglé"
                      : "Programmes épinglés"}
                  </h2>
                  <div className="rowSlideInner no-scrollbar">
                    {pinnedProgrammes.map((pinnedProgramme, index) => {
                      const nbSeances = pinnedProgramme?.seances?.planificationProgramme?.reduce(
                        (acc, planification) =>
                          acc +
                          (planification?.synchProgrammesSeances
                            ? planification?.synchProgrammesSeances?.nodes?.filter(
                                (seance) => viewerIsAdmin || seance.status === "publish"
                              ).length
                            : 0),
                        0
                      );

                      const filteredData = avancementUser?.filter(
                        (item) => item.programmeId === pinnedProgramme?.databaseId.toString()
                      );
                      const completedSeances = filteredData?.reduce(
                        (total, item) => total + item.seances?.length,
                        0
                      );
                      const avancementPerCent = Math.round(
                        calculateProgression(nbSeances, completedSeances)
                      );

                      const isAccessible = viewerIsFreemium
                        ? pinnedProgramme?.datasProgrammes?.enableFreemiumAccount
                        : true;

                      return (
                        <ProgrammesCompo
                          onClick={() => handleProgrammeClick(pinnedProgramme?.id)}
                          key={index}
                          niveau={pinnedProgramme?.niveaux?.edges?.[0]?.node?.name}
                          niveauSlug={pinnedProgramme?.niveaux?.edges?.[0]?.node?.slug}
                          label={pinnedProgramme?.title}
                          nbSeance={nbSeances}
                          src={
                            pinnedProgramme?.thumbnailsProgramme?.thumbnailProgrammePaysage
                              ?.node?.sourceUrl
                          }
                          srcSet={
                            pinnedProgramme?.thumbnailsProgramme?.thumbnailProgrammePaysage
                              ?.node?.srcSet
                          }
                          alt={
                            pinnedProgramme?.thumbnailsProgramme?.thumbnailProgrammePaysage
                              ?.node?.altText
                          }
                          id={pinnedProgramme?.id}
                          {...(viewerIsAdmin && { status: pinnedProgramme?.status })}
                          avancement={avancementPerCent}
                          freemiumAccess={isAccessible}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            )}


            {programmeUneApp && (
              <div className='programmeUne'>
                <h2 className='titleUne'>{titleProgrammeUneApp}</h2>
                <ProgrammesCompo
                  onClick={() => handleProgrammeClick(programmeUneApp?.id)}
                  niveau={programmeUneApp?.niveaux?.edges?.[0]?.node?.name}
                  niveauSlug={programmeUneApp?.niveaux?.edges?.[0]?.node?.slug}
                  label={programmeUneApp?.title}
                  nbSeance={nbSeancesProgrammeUne}
                  description={programmeUneApp?.content}
                  alt={programmeUneApp?.thumbnailsProgramme?.thumbnailProgrammePortrait?.node?.altText}
                  src={programmeUneApp?.thumbnailsProgramme?.thumbnailProgrammePortrait?.node?.sourceUrl}
                  srcSet={programmeUneApp?.thumbnailsProgramme?.thumbnailProgrammePortrait?.node?.srcSet}
                  id={programmeUneApp?.id}
                  avancement={avancementPerCent}
                  freemiumAccess={viewerIsFreemium ? programmeUneApp?.datasProgrammes?.enableFreemiumAccount : true}
                />
              </div>
            )}

            <div className='slidesBlock'>
              {slidesApp?.map((slide, index) => (
                <div className='rowSlide' key={index}>
                  <h2 className='titleUne'>{slide.titre}</h2>
                  <div className='rowSlideInner no-scrollbar'>
                    {slide?.rubrique?.nodes.map((cardSlide, slideIndex) => {
                      const navigateFromCadSlide = (category, slugTerm) => {
                        navigate('/entrainements', {
                          state: { filterFromCardSlide: { [category]: slugTerm } },
                        });
                      };
                      return (
                        <div
                          onClick={() => navigateFromCadSlide(cardSlide.taxonomyName, cardSlide.slug)}
                          className='cardSlide'
                          key={slideIndex}
                        >
                          <div key={cardSlide.id}>
                            {cardSlide.termsConfig &&
                            cardSlide.termsConfig.imageUne &&
                            cardSlide.termsConfig.imageUne.node ? (
                              <img
                                src={cardSlide?.termsConfig?.imageUne?.node?.sourceUrl}
                                srcSet={cardSlide?.termsConfig?.imageUne?.node?.srcSet}
                                alt={cardSlide?.termsConfig?.imageUne?.node?.altText}
                              />
                            ) : (
                              <img src={DefaultImage} alt='Default' />
                            )}
                            <p>{cardSlide.name}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>

            <div className='bannerSuivi'>
              <div className='bannerSuiviInner'>
                <div className='imgSide'>
                  <img src={MontageImage} alt='Montage Progression Suivi Flexifit' />
                </div>
                <div className='contentSide'>
                  <h3>
                    <FontAwesomeIcon icon='fa-regular fa-chart-simple' />
                    Évaluez vos progrès
                  </h3>
                  <p>
                  Testez notre outil de calcul d’angle intelligent pour évaluer instantanément votre amplitude et créer vos propres avant/après.
                  </p>
                  <button
                    className='btn'
                    onClick={() => {
                      navigate('/evolution');
                    }}
                  >
                    Envoyer mes photos
                  </button>
                </div>
              </div>
            </div>

            <div className='conseilsUne'>
              <h2 className='titleUne'>Conseils</h2>
              <div className='conseilsUneContent'>
                {conseilsUneApp?.map((conseil, i) => {
                  const conseilPublished = conseil?.configConseils.publierApp;
                  if (conseilPublished)
                    return (
                      <ConseilsCompo
                        id={conseil.id}
                        key={i}
                        onClick={() => handleConseilClick(conseil.id)}
                        label={conseil?.title}
                        src={conseil?.thumbnailsConseil?.thumbnailConseilPaysage?.node?.sourceUrl}
                        srcSet={conseil?.thumbnailsConseil?.thumbnailConseilPaysage?.node?.srcSet}
                        alt={conseil?.thumbnailsConseil?.thumbnailConseilPaysage?.node?.altText}
                        freemiumAccess={viewerIsFreemium ? conseil?.configConseils?.enableFreemiumAccount : true}
                      />
                    );
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
