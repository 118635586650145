import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';
import { useMutation } from '@apollo/client';
import html2canvas from 'html2canvas';
 
import {
  GET_SUIVIS_QUERY,
  POST_PROGRESSION_MUTATION,
  POST_EVOLUTION_MUTATION,
  GET_FIGURES_SUIVI,
  DELETE_PROGRESSION_MUTATION,
  DELETE_EVOLUTION_MUTATION,
} from '../../api';

// IMPORT COMPONENTS
import './Suivi.css';
 
import SuiviModal from '../../components/SuiviModal';
import SuiviCompo from '../../components/actions/SuiviCompo';
import CropImageModal from '../../components/evolution/CropImageModal';
import { convertDateISOToJJMMAAAA, todayDate } from '../../utils/stringsUtils';
import SelectProgressionImageModal from '../../components/evolution/SelectProgressionImageModal';

// Imports FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faTrash, faUser, faBadgeCheck, faCircleExclamation, faCircleInfo, faArrowRotateLeft, faArrowsUpDownLeftRight } from '@fortawesome/pro-solid-svg-icons';

library.add(faTrash, faUser, faBadgeCheck, faCircleExclamation, faCircleInfo, faArrowRotateLeft, faArrowsUpDownLeftRight);


export default function Suivi() {

  useEffect(() => {
    sessionStorage.removeItem('searchValue');
    sessionStorage.removeItem('filters');
    sessionStorage.removeItem('filterByType');
  }, []);

  const { databaseId, token } = useSelector((state) => state.user);

  const apolloContext = {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  }; 

  const { data, error: errorToken, refetch } = useQuery(GET_SUIVIS_QUERY);
  const { data: dataFigures } = useQuery(GET_FIGURES_SUIVI);

  const [postProgression, { loading }] = useMutation(POST_PROGRESSION_MUTATION, apolloContext);
  const [postEvolution, { loading: loadingEvolution }] = useMutation(POST_EVOLUTION_MUTATION, apolloContext);

  const [deleteProgression] = useMutation(DELETE_PROGRESSION_MUTATION, apolloContext);
  const [deleteEvolution] = useMutation(DELETE_EVOLUTION_MUTATION, apolloContext);

  const navigate = useNavigate();

  // Tabs
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
    setFigure('');
    setFilterFigure('');
  };

  // GET figures list

  const allFigures = dataFigures?.configurations?.appConfig?.figuresListProgressions?.nodes?.map((figure, index) => (
    <option key={index} data-slug={figure?.figuresConfigPosetracker?.slugPosetracker} value={figure.databaseId}>
      {figure.name}
    </option>
  ));

  // Progression
  const [originAvant, setOriginAvant] = useState('');
  const [originApres, setOriginApres] = useState('');
  const [isAvantCropped, setIsAvantCropped] = useState(false);
  const [isApresCropped, setIsApresCropped] = useState(false);
  const [avant, setAvant] = useState('');
  const [apres, setApres] = useState('');
  const [note, setNote] = useState('');
  const [usage, setUsage] = useState(false);
  const [disposition, setDisposition] = useState(true);
  const [openCropImageAvantModal, setOpenCropImageAvantModal] = useState(false);
  const [openCropImageApresModal, setOpenCropImageApresModal] = useState(false);

  const convertToBase64 = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const img = new Image();
        img.src = fileReader.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          // Calculer les nouvelles dimensions tout en conservant le ratio
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          resolve(canvas.toDataURL('image/jpg'));
        };
        img.onerror = (error) => {
          reject(error);
        };
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }; 

  const handleAvantChange = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertToBase64(file, 1500, 1500);
    setAvant(base64);
    setOriginAvant(base64);
    setOpenCropImageAvantModal(true);  
  };

  const handleApresChange = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertToBase64(file, 1500, 1500);
    setApres(base64);
    setOriginApres(base64);
    setOpenCropImageApresModal(true);
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  // Évolution

  const [figureSlug, setFigureSlug] = useState('');
  const [imageCaptured, setImageCaptured] = useState('');
  const [angle, setAngleCaptured] = useState('');
  const [showImage, setShowImage] = useState(false); // État pour gérer l'affichage de l'image

  const [noteEvolution, setNoteEvolution] = useState('');


  const [openSelectProgressionImageAvantModal, setOpenSelectProgressionImageAvantModal] = useState(false);
  const [openSelectProgressionImageApresModal, setOpenSelectProgressionImageApresModal] = useState(false);


  const handleFigureChange = (e) => {
    const selectedOption = e.target.selectedOptions[0];
    setFigure(e.target.value);
    setFigureSlug(selectedOption.getAttribute('data-slug'));
  };

  const handleNoteEvolutionChange = (event) => {
    setNoteEvolution(event.target.value);
  };

  // Progression & Évolution

  const [figure, setFigure] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [figureVerif, setFigureVerif] = useState(false);

  const [filterFigure, setFilterFigure] = useState('');
  const [selectedProgression, setSelectedProgression] = useState(null);
  const [specificLoading, setSpecificLoading] = useState(true);

  // POST progression and message success / error

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');


  const handleSubmitProgression = async () => {
    if (!figure || figure === '') {
      setFigureVerif(true);
      return;
    }
    const userFormatted = databaseId;
    try {
      const montage = await html2canvas(finalImageRef.current, {
        useCORS: true, // Important pour les images cross-origin
        allowTaint: true,
        scale: 4, // Augmente la qualité du rendu        
      }).then((canvas) => {
        return canvas.toDataURL('image/png', 1);
      });

      const response = await postProgression({
        variables: {
          title: todayDate(),
          avant: originAvant,
          apres: originApres,
          montage,
          disposition,
          figure: Number(figure),
          usage,
          note,
          utilisateur: userFormatted,
        },
      });
      if (response.data) {
        setSuccessMessage('Votre avant/après a bien été généré ! Vous pouvez le retrouver ci-dessous.');
        setTimeout(() => {
          setSuccessMessage('');
        }, 4000);
        setAvant('');
        setApres('');
        setOriginAvant('');
        setOriginApres('');
        setDisposition(true);
        setIsApresCropped(false);
        setIsAvantCropped(false);
        setFigure('');
        setNote('');
        setUsage(false);
      }
      refetch();
    } catch (err) {
      console.log(err);
      setErrorMessage('Une erreur s\'est produite lors de l\'envoi de vos photos, veuillez réessayer ou contacter notre support.');
      setTimeout(() => {
        setErrorMessage('');
      }, 4000);
    }
  };

  const handleSetAvant = (source) => {
    setAvant(source)
    setOriginAvant(source)
    setOpenCropImageAvantModal(true)
  }

  const handleSetApres = (source) => {
    setApres(source)
    setOriginApres(source)
    setOpenCropImageApresModal(true)
  }

  // POST evolution and message success / error

  const [errorMessageEvolution, setErrorMessageEvolution] = useState('');
  const [successMessageEvolution, setSuccessMessageEvolution] = useState('');

  const handleSubmitEvolution = async () => {
    if (!figure || figure === '') {
      setFigureVerif(true);
      return;
    }
    const userFormatted = databaseId;
    try {
      const response = await postEvolution({
        variables: {
          title: todayDate(),
          image: imageCaptured,
          angleEvolution: Number(angle),
          figure: Number(figure),
          noteEvolution,
          utilisateurEvolution: userFormatted,
        },
      });
      if (response.data) {
        setSuccessMessageEvolution('Votre amplitude a bien été générée ! Vous pouvez la retrouver ci-dessous.');
        setTimeout(() => {
          setSuccessMessageEvolution('');
        }, 4000);
        setShowImage(false);
        setImageCaptured('');
        setAngleCaptured('');
        setFigure('');
        setFigureSlug('');
        setNoteEvolution('');
      }
      refetch();
    } catch (err) {
      console.log(err);
      setErrorMessageEvolution('Une erreur s\'est produite lors de l\'enregistrement de votre amplitude, veuillez réessayer ou contacter notre support.');
      setTimeout(() => {
        setErrorMessageEvolution('');
      }, 4000);
    }
  };


  // GET progression

  let publishedProgression;
  const allProgression = data?.viewer?.userDatas?.progression?.nodes || [];
  if (filterFigure !== '') {
    publishedProgression = allProgression?.filter(
      (progression) =>
        progression?.figures?.nodes[0]?.databaseId === parseInt(filterFigure) &&
        progression.status === 'publish'
    );
  } else {
    publishedProgression = allProgression?.filter(
      (progression) => progression.status === 'publish'
    );
  }

  // GET evolution 

  let publishedEvolution;
  const allEvolution = data?.viewer?.userDatas?.evolution?.nodes || [];
  if (filterFigure !== '') {
    publishedEvolution = allEvolution?.filter(
      (evolution) =>
        evolution?.figures?.nodes[0]?.databaseId === parseInt(filterFigure) &&
        evolution.status === 'publish'
    );
  } else {
    publishedEvolution = allEvolution?.filter(
      (evolution) => evolution.status === 'publish'
    );
  }


  // DELETE progression or evolution

  const [deleteLoading, setDeleteLoading] = useState('');
  const [hasDeleteError, setDeleteError] = useState('');

  const handleDeleteProgression = async (id) => {
    try {
      setDeleteError(false);
      setDownloadError(false);
      setDeleteLoading(true);
      const response = await deleteProgression({
        variables: {
          id,
        },
      });
      setSelectedProgression(null);
      setOpenModal(false);
      refetch();
      setDeleteLoading(false);
    } catch (err) {
      console.log(err);
      setDeleteLoading(false);
      setDeleteError(true);
    }
  };

  const handleDeleteEvolution = async (id) => {
    try {
      setDeleteError(false);
      setDownloadError(false);
      setDeleteLoading(true);
      const response = await deleteEvolution({
        variables: {
          id,
        },
      });
      setSelectedProgression(null);
      setOpenModal(false);
      refetch();
      setDeleteLoading(false);
    } catch (err) {
      console.log(err);
      setDeleteLoading(false);
      setDeleteError(true);
    }
  };

  // DOWNLOAD progression or evolution

  const [downloadLoading, setDownloadLoading] = useState('');
  const [hasDownloadError, setDownloadError] = useState('');

  const handleDownload = async (imageUrl) => {
    try {
        setDeleteError(false);
        setDownloadError(false);
        setDownloadLoading(true);

        // Fetch the image through the proxy server
        const response = await fetch(`/api/proxy?url=${encodeURIComponent(imageUrl)}`, {
          headers: {
            'Accept': 'image/png'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch image');
        }

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = 'image.png';
        document.body.appendChild(link);
        link.click();

        URL.revokeObjectURL(blobUrl);
        document.body.removeChild(link);

        setDownloadLoading(false);
    } catch (err) {
        console.log(err);
        setDownloadLoading(false);
        setDownloadError(true);
    }
  };


  // Définir des valeurs en fonction de la tab

  const titleModal = activeTab === 1
  ? 'Mon avant/après'
  : 'Mon amplitude'

  const sourceUrlModal = activeTab === 1
  ? selectedProgression?.progressionDatas?.montageProgression?.node.sourceUrl
  : selectedProgression?.evolutionsDatas?.imageEvolution?.node.sourceUrl;

  const commentaireModal = activeTab === 1
  ? selectedProgression?.progressionDatas?.commentaireProgression
  : selectedProgression?.evolutionsDatas?.commentaireEvolution;

  const handleDownloadUrlModal = activeTab === 1
  ? selectedProgression?.progressionDatas?.montageProgression?.node.sourceUrl
  : selectedProgression?.evolutionsDatas?.imageEvolution?.node.sourceUrl;

  const handleDelete = activeTab === 1
  ? () => handleDeleteProgression(selectedProgression?.databaseId)
  : () => handleDeleteEvolution(selectedProgression?.databaseId);


  // useEffect

  useEffect(() => {
    setFigureVerif(false);
  }, [figure]);

  useEffect(() => {
    if (data) {
      setSpecificLoading(false);
    }
  }, [data]);





  // Fonction pour empêcher le défilement
  const preventTouchMove = (event) => {
    event.preventDefault();
  };

  // Fonction pour désactiver le scroll
  const disableScroll = () => {
    const scrollY = window.scrollY;
    document.body.style.top = `-${scrollY}px`;
    document.body.classList.add('no-scroll');
    document.addEventListener('touchmove', preventTouchMove, { passive: false });
  };

  // Fonction pour réactiver le scroll
  const enableScroll = () => {
    document.body.classList.remove('no-scroll');
    const scrollY = parseInt(document.body.style.top || '0');
    document.body.style.top = '';
    window.scrollTo(0, Math.abs(scrollY));
    document.removeEventListener('touchmove', preventTouchMove);
  };

  const handleMessagePoseTracker = (eventPoseTracker) => {
    if (typeof eventPoseTracker.data !== 'string') {
      return;
    }
    try {
      const data = JSON.parse(eventPoseTracker.data);
      if (data.type === 'error' || data.type === 'initialization' || data.type === 'posture') {
        return;
      }
      if (data.type === 'image') {
        enableScroll();
        setImageCaptured(data.annotatedScreenshot);
        setAngleCaptured(data.angles);
        setShowImage(true);
      } else if (data.type === 'editing') {
        disableScroll();
      } else if (data.type === 'exitEditing') {
        enableScroll();
      } else {
        console.log("Unhandled data type: ", data);
      }
    } catch (e) {
      console.error("Error parsing message", e);
    }
  };

  useEffect(() => {

    window.addEventListener('message', handleMessagePoseTracker);
    
    return () => {
      window.removeEventListener('message', handleMessagePoseTracker);
      enableScroll();
    };
  }, []);


  const resetCapture = () => {
    setShowImage(false); // Masquer l'image
    setImageCaptured(''); // Réinitialiser l'URL de l'image
    setAngleCaptured(''); // Réinitialiser l'angle calculé
  };

  const finalImageRef = useRef()
  
  const handleDeleteImageAvant = () => {
    setAvant('');
    setIsAvantCropped(false);
    setOriginAvant('');
  }

  const handleDeleteImageApres = () => {
    setApres('');
    setIsApresCropped(false);
    setOriginApres('');
  }

  const handleAbortModal = (side) => {
    if(side === 'avant'){
      if(!isAvantCropped){
        handleDeleteImageAvant()
      }
      setOpenCropImageAvantModal(false);
    } 
    if(side === 'apres'){
      if(!isApresCropped){
        handleDeleteImageApres()
      }
      setOpenCropImageApresModal(false);
    }
  }




  const [iframeWidth, setIframeWidth] = useState(340); // Largeur par défaut
  const [iframeHeight, setIframeHeight] = useState(500); // Largeur par défaut

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 600) {
        setIframeWidth(340); // Mobile
        setIframeHeight(500); // Mobile
      } else if (width <= 768) {
        setIframeWidth(600); // Tablette
        setIframeHeight(700); // Mobile
      } else if (width <= 992) {
        setIframeWidth(500); // Tablette
        setIframeHeight(650); // Mobile
      } else {
        setIframeWidth(600); // Desktop
        setIframeHeight(700); // Mobile
      }
    };

    // Appeler handleResize au montage initial
    handleResize();

    // Ajouter un écouteur d'événement pour le redimensionnement
    window.addEventListener('resize', handleResize);

    // Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className='w-full'>

      <Helmet>
        <title>Évolution</title>
      </Helmet>

      <div className='topBloc background sticked content'>
        <div className='leftSide'>
          <h1>Évolution</h1>
        </div>

        <div className='rightSide'>
          <div
            className='IconProfil lg:hidden'
            onClick={() => {
              navigate('/profil');
            }}
          ><FontAwesomeIcon icon='fa-solid fa-user' /></div>
        </div>
      </div>

      <div className="blurredPattern perle"></div>

      <div className='contentSuivi content'>

        <div className="tabs">
          <div className="tab-buttons">
            <button onClick={() => handleTabClick(0)} className={activeTab === 0 ? 'active' : ''}>
              Amplitude
            </button>
            <button onClick={() => handleTabClick(1)} className={activeTab === 1 ? 'active' : ''}>
              Avant / Après
            </button>
          </div>

          <div className="tab-content">

            {activeTab === 0 && (

              <div className="tab-pane">

                <div className='chapoPage'>
                  <h2>Mesurer une amplitude</h2>
                  <p>Choisissez une figure que vous souhaitez mesurer puis suivez les instructions.</p>
                </div>

                <div className="validateBlock">

                  <div>
                    <select className='chooseFigures' disabled={showImage} onChange={handleFigureChange} value={figure}>
                      <option value=''>Choisir une figure</option>
                      {allFigures}
                    </select>
                    {figureVerif && <p className='alert'>Veuillez sélectionner une figure</p>}
                  </div>

                </div>

                {figure &&
                  <div className='poseTracker'>

                    {showImage ? (
                      <>

                        <img src={imageCaptured} alt="Annotated Screenshot" />

                        {!loadingEvolution && (
                          <>
                            <button className="btn-link icon reset" onClick={resetCapture}><FontAwesomeIcon icon='fa-solid fa-arrow-rotate-left' />Reprendre la mesure</button>

                            <div className="validateBlock">

                              <div className='commentaire'>
                                <label>
                                  Commentaire<span>(facultatif)</span>
                                  <textarea name="commentaire" onChange={handleNoteEvolutionChange} value={noteEvolution} />
                                </label>
                              </div>

                              <button disabled={loadingEvolution} className='btn' onClick={handleSubmitEvolution}>
                                Enregistrer mon amplitude
                              </button>
                            </div>
                          </>
                        )}

                      </>
                    ) : (
                      <div style={{ width: `${iframeWidth}px`, height: `${iframeHeight}px`, margin: 'auto' }}>
                        <iframe
                          id="poseTrackerFrame"
                          src={`https://app.posetracker.com/pose_tracker/flexifit/angles?token=${process.env.REACT_APP_POSE_TRACKER_TOKEN}&exercise=${figureSlug}&width=${iframeWidth}&height=${iframeHeight}&instructions=${publishedEvolution.length > 0 ? 'false' : 'true'}`}
                          allow="camera *;"
                          style={{ width: '100%', height: `${iframeHeight}px`, border: 'none', minHeight: `${iframeHeight}px` }}
                        />
                      </div>
                    )}

                  </div>
                }

                {loadingEvolution && (
                  <div className='loading'>
                    <FontAwesomeIcon className='animate-spin' icon='fa-duotone fa-spinner-third' />
                    <span>Enregistrement en cours... Cela peut prendre quelques secondes</span>
                  </div>
                )}

                {errorMessageEvolution && (
                  <div className='alert error-message'>
                    <FontAwesomeIcon icon='fa-solid fa-circle-exclamation' />
                    <span>{errorMessageEvolution}</span>
                  </div>
                )}

                {successMessageEvolution && (
                  <div className='alert validate success-message'>
                    <FontAwesomeIcon icon='fa-solid fa-badge-check' />
                    <span>{successMessageEvolution}</span>
                  </div>
                )}

                <div className='userProgressions'>
                  <div className='topUserProgressions'>
                    <h2>Mes amplitudes</h2>

                    <select className='chooseFigures' onChange={(e) => setFilterFigure(e.target.value)}>
                      <option value=''>Filtrer - Toutes figures</option>
                      {allFigures}
                    </select>
                  </div>

                  {specificLoading ? (
                    <div className='galleryUserProgressions loading'>
                      <FontAwesomeIcon className='animate-spin' icon='fa-duotone fa-spinner-third' />
                    </div>
                  ) : publishedEvolution.length > 0 ? (
                    <div className='galleryUserProgressions'>
                      {publishedEvolution.map((evolution, index) => {
                        const formattedDate = convertDateISOToJJMMAAAA(evolution?.date);
                        return (
                          <SuiviCompo
                            key={index}
                            figure={evolution?.figures?.nodes[0]?.name}
                            date={formattedDate}
                            source={evolution?.evolutionsDatas?.imageEvolution?.node.sourceUrl}
                            onClick={() => {
                              setOpenModal(true);
                              setSelectedProgression(evolution);
                            }}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <div className='galleryUserProgressions noResult'>
                      <p>Vous n'avez pas encore d'amplitudes enregistrées</p>
                    </div>
                  )}

                </div>

              </div>

            )}

            {activeTab === 1 && (
              <div className="tab-pane">
                <div className='chapoPage'>
                  <h2>Créer un avant/après</h2>
                  <p>
                    Déposez une photo avec un point de départ et une autre photo avec un point à évaluer.
                    Notre outil génèrera votre avant/après.
                  </p>
                </div>

                <div className='how-to'>
                    <div className='alert informations info-message'>
                      <a href="https://app.flexifit.app/conseils/cG9zdDoxNTA3">
                        <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                        <span>Retrouvez ici quelques conseils pour bien prendre vos photos et que nous puissions calculer votre progression !</span>
                      </a>
                    </div>
                </div>

                <div ref={finalImageRef} id="finalImage" className={'canvasProgression ' + (disposition ? 'portrait' : 'paysage')}>
                  <div style={{backgroundImage: `url(${avant})`}} className='canvasBeforeProgression bg-cover bg-center'>
                    
                    {!avant && (
                      <div className='canvasActions'>
                        <p className='label'>Avant</p>
                        <div className='chooseImg'>
                          <button className='btn-sm btn'>Depuis mon appareil</button>
                          <input type='file' accept='image/*' onChange={handleAvantChange} />
                        </div>
                        <div className='chooseImg'>
                          <button
                            onClick={() => setOpenSelectProgressionImageAvantModal(true)}
                            className='btn-sm btn'
                          >
                              Depuis mes amplitudes
                          </button>
                        </div>
                      </div>
                    )}

                    {avant && isAvantCropped && <img src={avant} syle={{avant}} alt='Thumnbail progression avant' />}

                    {!loading && avant && (
                      <div data-html2canvas-ignore className="imageActions">
                        <button className='reframeImg' onClick={() => setOpenCropImageAvantModal(true)}>
                          <FontAwesomeIcon icon='fa-solid fa-arrows-up-down-left-right' />
                        </button>
                        <button className='deleteImg' onClick={handleDeleteImageAvant}>
                          <FontAwesomeIcon icon='fa-solid fa-trash' />
                        </button>
                      </div>
                    )}
                  </div>

                  <div className='canvasSep'></div>

                  <div style={{backgroundImage: `url(${apres})`}} className='canvasAfterProgression bg-cover bg-center'>
                    {!apres && (
                      <div className='canvasActions'>
                        <p className='label'>Après</p>
                        <div className='chooseImg'>
                          <button className='btn-sm btn'>Depuis mon appareil</button>
                          <input type='file' accept='image/*' onChange={handleApresChange} />
                        </div>
                        <div className='chooseImg'>
                          <button
                            onClick={() => setOpenSelectProgressionImageApresModal(true)}
                            className='btn-sm btn'
                          >
                              Depuis mes amplitudes
                          </button>
                        </div>
                      </div>
                    )}

                    {apres && isApresCropped && <img src={apres} alt='Thumnbail progression après' />}

                    {!loading && apres && (
                      <div data-html2canvas-ignore className="imageActions">
                        <button className='reframeImg' onClick={() => setOpenCropImageApresModal(true)}>
                          <FontAwesomeIcon icon='fa-solid fa-arrows-up-down-left-right' />
                        </button>
                        <button className='deleteImg' onClick={handleDeleteImageApres}>
                          <FontAwesomeIcon icon='fa-solid fa-trash' />
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                {!loading && (
                  <div className='switcherDispo'>
                    <input
                      type='checkbox'
                      id='switch'
                      checked={disposition}
                      onChange={() => {
                        setDisposition(!disposition); 
                        setIsApresCropped(false);
                        setIsAvantCropped(false);
                        setAvant('');
                        setApres('');
                      }}
                    />
                    <label htmlFor='switch'>
                      <div className='toggle'></div>
                      <div className='names'>
                        <p className='portrait'>Portrait</p>
                        <p className='paysage'>Paysage</p>
                      </div>
                    </label>
                  </div>
                )}
                

                {!loading && apres && avant && (
                  <div className='validateBlock'>

                    <div>
                      <select className='chooseFigures' onChange={(e) => setFigure(e.target.value)}>
                        <option value=''>Choisir une figure</option>
                        {allFigures}
                      </select>
                      {figureVerif && <p className='alert'>Veuillez sélectionner une figure</p>}
                    </div>

                    <div className='commentaire'>
                      <label>
                        Commentaire<span>(facultatif)</span>
                        <p className="text-black text-[13px] font-medium">Tu peux écrire-ici les dates de ces photos, tes ressentis, tes objectifs. Si tu as une question, un membre de l’équipe te répondra directement ici dans quelques jours.</p>
                        <textarea name="commentaire" onChange={handleNoteChange} value={note} />
                      </label>
                    </div>

                    <button disabled={loading} className='btn' onClick={handleSubmitProgression}>
                      Générer mon Avant/Après
                    </button>

                    <p className='autorization'>
                      En validant votre avant/après, vous autorisez Flexifit à utiliser vos images pour
                      concevoir un montage de avant/après.
                    </p>

                    <div className='autorize'>
                      <label>
                        <span></span>
                        J’autorise Flexifit à utiliser ma progression sur le site et les réseaux sociaux de la plateforme
                        <input type='checkbox' checked={usage} onChange={() => setUsage(!usage)} />
                      </label>
                    </div>
                  </div>
                )}

                {loading && (
                  <div className='loading'>
                    <FontAwesomeIcon className='animate-spin' icon='fa-duotone fa-spinner-third' />
                    <span>Envoi en cours</span>
                  </div>
                )}

                {errorMessage && (
                  <div className='alert error-message'>
                    <FontAwesomeIcon icon='fa-solid fa-circle-exclamation' />
                    <span>{errorMessage}</span>
                  </div>
                )}

                {successMessage && (
                  <div className='alert validate success-message'>
                    <FontAwesomeIcon icon='fa-solid fa-badge-check' />
                    <span>{successMessage}</span>
                  </div>
                )}

                <div className='userProgressions'>
                  <div className='topUserProgressions'>
                    <h2>Mes avant/après</h2>

                    <select className='chooseFigures' onChange={(e) => setFilterFigure(e.target.value)}>
                      <option value=''>Filtrer - Toutes figures</option>
                      {allFigures}
                    </select>
                  </div>

                  {specificLoading ? (
                    <div className='galleryUserProgressions loading'>
                      <FontAwesomeIcon className='animate-spin' icon='fa-duotone fa-spinner-third' />
                    </div>
                  ) : publishedProgression.length > 0 ? (
                    <div className='galleryUserProgressions'>
                      {publishedProgression.map((progression, index) => {
                        const formattedDate = convertDateISOToJJMMAAAA(progression?.date);
                        return (
                          <SuiviCompo
                            key={index}
                            figure={progression?.figures?.nodes[0]?.name}
                            date={formattedDate}
                            source={progression?.progressionDatas?.montageProgression?.node.sourceUrl}
                            onClick={() => {
                              setOpenModal(true);
                              setSelectedProgression(progression);
                            }}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <div className='galleryUserProgressions noResult'>
                      <p>Vous n'avez pas encore de avant/après</p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>



      {openModal && (
        <SuiviModal
          closeModal={() => setOpenModal(false)}
          titleModal =  {titleModal}
          isDownloadState = {downloadLoading}
          hasDownloadError = {hasDownloadError}
          figure={selectedProgression?.figures?.nodes[0]?.name}

          // progressionDatas?.montageProgression?.node.sourceUrl ou evolutionsDatas?.imageEvolution?.node.sourceUrl
          source={sourceUrlModal}
          // progressionDatas?.montageProgression?.node.sourceUrl ou evolutionsDatas?.imageEvolution?.node.sourceUrl
          onDownload={() => handleDownload(handleDownloadUrlModal)}
          // progressionDatas?.commentaireProgression ou evolutionsDatas?.commentaireEvolution
          commentaireFlexifit={commentaireModal}

          date={convertDateISOToJJMMAAAA(selectedProgression?.date)}
          onDelete={handleDelete}
          isDeleteState= {deleteLoading}
          hasDeleteError = {hasDeleteError}
        />
      )}

      {
        (openSelectProgressionImageAvantModal || openSelectProgressionImageApresModal) && (
          <SelectProgressionImageModal
            closeModal={() => {setOpenSelectProgressionImageAvantModal(false); setOpenSelectProgressionImageApresModal(false)}}
            setFilterFigure={setFilterFigure}
            allFigures={allFigures}
            publishedEvolution={publishedEvolution}
            handleSrc={openSelectProgressionImageAvantModal ? handleSetAvant : handleSetApres}
          />
        )
      }

      {
        (openCropImageAvantModal || openCropImageApresModal) && (
          <CropImageModal
            closeModal={() => {setOpenCropImageAvantModal(false); setOpenCropImageApresModal(false)}}
            abortModal={() => handleAbortModal(openCropImageAvantModal ? 'avant' : 'apres')}
            image={openCropImageAvantModal ? originAvant : originApres}
            setCropped={openCropImageAvantModal ? setAvant : setApres}
            disposition={disposition}
            setIsCropped={openCropImageAvantModal ? setIsAvantCropped : setIsApresCropped}
          />
        )
      }
    </div>
  );
}
